
// FULL POPUP STUFF
function checkScrollbar() {
  //Get real width of page scroll-bar
  const w1 = document.documentElement.clientWidth;
  document.querySelector('html').classList.add('popup_lock');
  const w2 = document.documentElement.clientWidth;
  document.querySelector('html').classList.remove('popup_lock');
  return w2 - w1;
}

// FULL POPUP STUFF
function bodyStopScroll() {
  const scrollWidth = checkScrollbar();
  const stopScrollStyle = `
      <style id="scrollStuff">
        html {overflow: hidden;}
        body, .js_noscrol_pad { padding-right: ${scrollWidth}px; }
        body { width:auto; }
      </style>
    `;
  document.head.insertAdjacentHTML('beforeend', stopScrollStyle);
  document.body.classList.add('noscroll');
}
function bodyRemoveStopScroll() {
  if (document.head.querySelector('#scrollStuff')) {
    document.head.querySelector('#scrollStuff').remove();
  }
  document.body.classList.remove('noscroll');
}
// ==============



// let's get this party started
document.addEventListener('DOMContentLoaded', function () {





  // COPY TO CLIPBOARD
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    document.body.classList.add('cancopy');

    const copyToClipboardAsync = str => {
      return navigator.clipboard.writeText(str);
      // return Promise.reject('The Clipboard API is not available.');
    }

    document.querySelectorAll('.js_copy_trigger').forEach(trigger => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();

        if (!document.body.classList.contains('holdcopy')) {

          const holder = trigger.closest('.js_copy');
          const text = holder.querySelector('.js_copy_text').textContent.replace(/\s+/g, ' ').trim();
          copyToClipboardAsync(text);



          const isToast = document.querySelector('.toast_copy');
          if (isToast) {
            isToast.remove();
          }
          const toastCopy = `<div class="toast toast_copy">
        Скопировано в буфер обмена
      </div>`;
          document.body.classList.add('holdcopy');
          document.body.insertAdjacentHTML('beforeend', toastCopy);

          setTimeout(() => {
            document.querySelector('.toast_copy').classList.add('m-active');
          }, 10);
          setTimeout(() => {
            document.querySelector('.toast_copy').classList.remove('m-active');
            document.body.classList.remove('holdcopy');
          }, 1000);

        }
      });
    });
  }
  // ===================






  // ADD YEAR
  const currYear = new Date().getFullYear();
  if (currYear > 2023) {
    document.querySelectorAll('.js_year').forEach(item => {
      item.innerHTML = `${currYear}`;
    });
  }



  // MAINSTAGE SLIDER
  const swiper_mainstage = new Swiper('.js_mainslider', {
    slidesPerView: 1,
    spaceBetween: 24,
    resistanceRatio: 0,
    loop: true,
    pagination: {
      el: '.slider__pagination',
      type: 'fraction',
    },
    navigation: {
      prevEl: '.slider__prev',
      nextEl: '.slider__next',
    },
  });

  // REGULAR SLIDER
  const swiper_regular = new Swiper('.js_slider', {
    slidesPerView: 1,
    spaceBetween: 24,
    resistanceRatio: 0,
    loop: true,
    pagination: {
      el: '.slider__pagination',
      type: 'fraction',
    },
    navigation: {
      prevEl: '.slider__prev',
      nextEl: '.slider__next',
    },
  });




  // INPUT STATUS
  function checkFill(item) {
    item.value.length
      ? item.classList.add('m-filled')
      : item.classList.remove('m-filled');
  }


  document.querySelectorAll('.js_checkfill').forEach(item => {
    checkFill(item)
    item.onfocus = function () {
      item.classList.add('m-focused');
    }
    item.onblur = function () {
      item.classList.remove('m-focused');
      checkFill(item)
    }
    item.onkeyup = function () {
      checkFill(item)
    }
  });


  // ========================

  // INPUT CLEAR
  document.querySelectorAll('.js_input_clear').forEach(item => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      const targetInputs = item.parentNode.querySelectorAll('input, textarea');
      targetInputs.forEach(input => {
        input.value = '';
        input.classList.remove('m-filled');
        input.focus();
      });
    });
  });

  // ========================

  // STUFF ON SCROLL
  function onScroll() {
    // detect scroll
    const scrolledGap = 42;
    const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    scrollTop > scrolledGap
      ? document.body.classList.add('m-scrolled')
      : document.body.classList.remove('m-scrolled');
    // =========
  }
  onScroll();
  let throttlescroll;
  window.addEventListener('scroll', function () {
    clearTimeout(throttlescroll)
    throttlescroll = setTimeout(function () {
      onScroll();
    }, 50)
  }, false)
  // ========================

  // SCROLL TO
  const scroller = new SweetScroll(
    {
      /* some options */
      trigger: '[data-scroll]',       // Selector for trigger (must be a valid css selector)
      header: '[data-scroll-header]', // Selector or Element for fixed header (Selector of must be a valid css selector)
      // header: '#header',
      duration: 1000,                 // Specifies animation duration in integer
      easing: 'easeInOutSine',         // Specifies the pattern of easing
      offset: 0,                      // Specifies the value to offset the scroll position in pixels
      vertical: true,                 // Enable the vertical scroll
      horizontal: false,              // Enable the horizontal scroll
      cancellable: true,              // When fired wheel or touchstart events to stop scrolling
      updateURL: false,               // Update the URL hash on after scroll (true | false | 'push' | 'replace')
      preventDefault: true,           // Cancels the container element click event
      stopPropagation: true,          // Prevents further propagation of the container element click event in the bubbling phase

      // Callbacks
      // before: null,
      after: (offset, $trigger, scroller) => {
        // move focus to target
        let targetItem;
        if (!$trigger && offset.top === 0) {
          targetItem = document.body;
        } else if ($trigger && $trigger.getAttribute('href')) {
          targetItem = document.querySelector($trigger.getAttribute('href'));
        } else if ($trigger && $trigger.getAttribute('data-scroll').substring(0, 1) === '#') {
          targetItem = document.querySelector($trigger.getAttribute('data-scroll'));
        }
        if (targetItem) {
          targetItem.focus();
          if (targetItem !== document.activeElement) {
            targetItem.setAttribute('tabindex', '-1');
            targetItem.focus();
            targetItem.blur();
            targetItem.removeAttribute('tabindex');
          }
        }
      },
      // cancel: null,
      // complete: null,
      // step: null,


      // // Stop scrolling case of trigger element that contains the `is-disabled` class.
      // before: (offset: Offset, $trigger: Element | null, scroller: SweetScroll): boolean | void => {
      //   console.log('Before!!', offset, scroller);
      //   if ($trigger && $trigger.classList.contains('is-disabled')) {
      //     return false;
      //   }
      // },

      // // If the `wheel` or `touchstart` event is called
      // cancel: (scroller: SweetScroll): void => {
      //   console.log('Cancel!!', scroller);
      // },

      // // Scroll animation is complete
      // after: (offset: Offset, $trigger: Element | null, scroller: SweetScroll): void => {
      //   console.log('After!!', offset, $trigger, scroller);
      // },

      // // Scroll animation is complete (`after` or `cancel`)
      // complete: (isCancel: boolean, scroller: SweetScroll): void => {
      //   console.log('Complete!!', isCancel, scroller);
      // },

      // // Each animation frame
      // step: (time: number, scroller: SweetScroll): void => {
      //   console.log('step', time, scroller);
      // },
    },
    // '#container',
    // document.getElementById('container'),
  );

  // scroller.to('#footer');
  // scroller.to({ top: 1000, left: 20 });
  // // Array (top:0, left:1000)
  // scroller.to([0, 1000]);
  // scroller.to(500);
  // scroller.to('+=500');
  // scroller.toTop(0);
  // scroller.toLeft(1500);
  // scroller.toElement(document.getElementById('content'));

  // scroller.update({
  //   trigger: 'a[href^="#"]',
  //   duration: 3000,
  // });

  // scroller.stop(true);
  // scroller.destroy();

  // SOME HTML OPTIONS
  // data-scroll="#target"
  // data-scroll="+=500"
  // data-scroll-options='{"easing": "easeOutExpo"}'

  // INITIAL SCROLL
  // document.addEventListener('DOMContentLoaded', function() {
  //   var scroller = new SweetScroll();
  //   var hash = window.location.hash;
  //   var needsInitialScroll = document.getElementById(hash.substr(1)) != null;

  //   if (needsInitialScroll) {
  //     window.location.hash = '';
  //   }

  //   window.addEventListener('load', function() {
  //     if (needsInitialScroll) {
  //       scroller.to(hash, { updateURL: 'replace' });
  //     }
  //   }, false);
  // }, false);


  document.querySelectorAll('[data-scroll-top]').forEach(item => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      scroller.toTop(0);
    });
  });
  //===============================




  // SLIDEDOWN
  let slideHold = false;
  const slideUp = (target, duration = 500) => {
    slideHold = true;
    target.classList.remove('m-active');
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
      slideHold = false;
    }, duration);
  }
  const slideDown = (target, duration = 500) => {
    target.classList.add('m-active');
    slideHold = true;
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
      slideHold = false;
    }, duration);
  }
  const slideToggle = (target, duration = 500) => {
    return window.getComputedStyle(target).display === 'none'
      ? slideDown(target, duration)
      : slideUp(target, duration);
  }

  document.querySelectorAll('.js_slidedown_trigger').forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      if (!slideHold) {
        let slideDuration = trigger.getAttribute('data-duration')
          ? trigger.getAttribute('data-duration')
          : 500;
        let thisTarget = trigger.getAttribute('data-target')
          ? document.querySelector(trigger.getAttribute('data-target'))
          : trigger.closest('.js_slidedown').querySelector('.js_slidedown_content');
        slideToggle(thisTarget, slideDuration);
        if (trigger.classList.contains('m-active')) {
          trigger.classList.remove('m-active');
          if (trigger.closest('.js_slidedown')) {
            trigger.closest('.js_slidedown').classList.remove('m-active');
          }
        } else {
          trigger.classList.add('m-active');
          if (trigger.closest('.js_slidedown')) {
            trigger.closest('.js_slidedown').classList.add('m-active');
          }
        }
      }
    });
  });
  // ========================

  // ACTIVE TOGGLE
  let toggleHold = false;
  document.querySelectorAll('.js_toggle_trigger').forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      if (!toggleHold) {
        toggleHold = true;
        let toggleTarget = ''
        if (trigger.getAttribute('data-target')) {
          toggleTarget = document.querySelector(trigger.getAttribute('data-target'))
        } else if (trigger.getAttribute('href')) {
          toggleTarget = document.querySelector(trigger.getAttribute('href'))
        } else {
          toggleTarget = trigger.closest('.js_toggle');
        }
        trigger.classList.toggle('m-active');
        toggleTarget.classList.toggle('m-active');

        setTimeout(() => {
          toggleHold = false;
        }, 500);
      }
    });
  });
  // ========================


  // FULL POPUP STUFF



  document.querySelectorAll('.js_popup_trigger').forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      const popupTarget = trigger.getAttribute('href')
        ? document.querySelector(trigger.getAttribute('href'))
        : document.querySelector(trigger.getAttribute('data-target'));
      bodyStopScroll();
      popupTarget.classList.add('m-active');

      trigger.classList.add('focusHolder');

      setTimeout(() => {
        popupTarget.setAttribute('tabindex', '-1');
        popupTarget.focus();
        popupTarget.blur();
        popupTarget.removeAttribute('tabindex');
      }, 500);
    });
  });


  document.querySelectorAll('.js_popup_close').forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      closePopup();
    });
  });


  let isEsc = false;
  document.addEventListener('keyup', function (event) {
    if (event.key === "Escape") {
      isEsc = true;
      closePopup();
    }
  });

  function closePopup() {
    const activePopup = document.querySelector('.js_popup.m-active');
    if (activePopup) {
      activePopup.classList.remove('m-active');
      setTimeout(() => {
        bodyRemoveStopScroll();

        const focusTarget = document.querySelector('.focusHolder');
        if (focusTarget) {
          focusTarget.focus();
          if (!isEsc) {
            focusTarget.blur();
          }
          focusTarget.classList.remove('focusHolder');
        }
        isEsc = false;
      }, 300);
    }
    else {
      isEsc = false;
    }
  }


  // ==========================


  let stopscrollHold = false;
  document.querySelectorAll('.js_stopscroll').forEach(trigger => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      if (!stopscrollHold) {
        stopscrollHold = true;
        if (!document.body.classList.contains('noscroll')) {
          bodyStopScroll();
        } else {
          setTimeout(() => {
            bodyRemoveStopScroll();
          }, 500);
        }
        setTimeout(() => {
          stopscrollHold = false;
        }, 500);
      }
    });
  });


  // ON HORIZONTAL RESIZE
  // - remove bodystopscroll
  let winWidth = window.innerWidth;
  let throttleresize;
  window.addEventListener('resize', function () {
    let newWinWidth = window.innerWidth;
    if (newWinWidth !== winWidth) {
      newWinWidth = newWinWidth;
      clearTimeout(throttleresize)
      throttleresize = setTimeout(function () {

        if (document.head.querySelector('#scrollStuff')) {
          bodyRemoveStopScroll();
        }

      }, 150)
    }
  }, false)



  // CUSTOM SELECT

  function customSelectInit() {

    var x, i, j, l, ll, selElmnt, a, b, c;
    /*look for any elements with the class "js_customselect":*/
    x = document.querySelectorAll(".js_customselect:not(.inited)");
    l = x.length;

    for (i = 0; i < l; i++) {
      // ~~~
      x[i].classList.add('inited');
      // ~~~


      const addWidth = x[i].classList.contains('cselect_short') ? -8 : 56;

      selElmnt = x[i].getElementsByTagName('select')[0];

      // ~~~
      const realWidth = selElmnt.offsetWidth;
      const selected = selElmnt.querySelectorAll('[selected]').length;
      x[i].style.width = `${realWidth + addWidth}px`;
      // ~~~

      ll = selElmnt.length;
      /*for each element, create a new DIV that will act as the selected item:*/
      a = document.createElement('DIV');
      a.setAttribute('class', 'cselect__current');
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /*for each element, create a new DIV that will contain the option list:*/
      b = document.createElement('DIV');
      b.setAttribute('class', 'cselect__options m-hide');
      for (j = 0; j < ll; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement('DIV');
        c.classList.add('cselect__option');
        // ~~~
        if (!selected && j == 0) {
          c.classList.add('m-current');
        } else if (selElmnt.options[j].hasAttribute('selected')) {
          c.classList.add('m-current');
        }
        // ~~~

        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener('click', function (e) {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName('select')[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName('m-current');
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].classList.remove('m-current');
              }
              this.classList.add('class', 'm-current');
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener('click', function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle('m-hide');
        this.classList.toggle('m-active');
      });
    }
    function closeAllSelect(elmnt) {
      /*a function that will close all select boxes in the document,
      except the current select box:*/
      var x, y, i, xl, yl, arrNo = [];
      x = document.getElementsByClassName('cselect__options');
      y = document.getElementsByClassName('cselect__current');
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove('m-active');
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add('m-hide');
        }
      }
    }
    /*if the user clicks anywhere outside the select box,
    then close all select boxes:*/
    document.addEventListener('click', closeAllSelect);
  }
  customSelectInit()









});


// =================================================
// =================================================
// =================================================

